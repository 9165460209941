import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BookingService } from '../../providers/booking.service';
import { EventPublishService } from '../../providers/event';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileEntry } from '@awesome-cordova-plugins/file';
import { CommonService } from '../../providers/common';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.page.html',
  styleUrls: ['./checkin.page.scss'],
})
export class CheckinPage implements OnInit {

  bookingData: any = [];
  checkInForm: any = FormGroup;
  idProof: any;
  idProofTwo: any;
  guestPhoto: any;
  idProofLoader = false;
  idProofTwoLoader = false;
  guestPhotoLoader = false;
  fileChangedEvent = true;
  fileSize: any;
  fileChangedEvent1: any;
  fileSize1: any;
  submitted = false;
  adults: any = 2;
  isSpinner = false;
  formType='normal'
  checklists: any = [
    {
      title: 'Bed and linens changed.',
      answer: false
    },
    {
      title: 'Bathroom cleaned.',
      answer: false
    },
    {
      title: 'Bottled water',
      answer: false
    },
    {
      title: 'Extra blanket and pillows',
      answer: false
    },
    {
      title: 'Tissue',
      answer: false
    },
    {
      title: 'Towels and Toilet Aminites',
      answer: false
    },
    {
      title: 'Furniture',
      answer: false
    },
    {
      title: 'Trash Can',
      answer: false
    },
    {
      title: 'Telivision and Remote',
      answer: false
    },
    {
      title: 'Light\'s',
      answer: false
    },
    {
      title: 'Kitchen Utensils',
      answer: false
    },

  ];

  win: any = window;



  constructor(private navParams: NavParams,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private modalCtrl: ModalController,
    private datePipe: DatePipe,
    private event: EventPublishService,
    private common: CommonService,
    public camera: Camera,
    private file: File,) {
    if (this.navParams.get('booking')) {
      this.bookingData = this.navParams.get('booking');
    }
    if (this.navParams.get('type')) {
      this.formType = this.navParams.get('type');
    }
  }
  get epf() { return this.checkInForm.controls; }

  get checklistValidation() {
    if(this.bookingData?.checkInCheckList?.length>0){
      return true;
    }
    let validation = true;
    this.checklists.forEach(element => {
      if (!element.answer) {
        validation = false;
      }
    });
    return validation;
  }

  ngOnInit() {
    this.checkInForm = this.fb.group({
      name: ['', Validators.required],
      idProof: ['',],
      checkIn: ['', Validators.required],
      guestPhoto: [''],
      address: ['', Validators.required],
      phone: ['', Validators.required],
      whatsappNo: ['', Validators.required],
      noOfAdults: ['', Validators.required],
      noOfKids: [''],
      useKitchenSet: [false],
      kitchenSetExpenseAmount: [''],
      guestDetails: this.fb.array([])
    });

    if (this.bookingData) {
      setTimeout(() => {
        this.checkInForm.get('name').patchValue(this.bookingData.name);
        this.checkInForm.get('checkIn').patchValue(this.datePipe.transform(this.bookingData.checkIn, 'yyyy-MM-dd'));
        this.checkInForm.get('address').patchValue(this.bookingData.address);
        this.checkInForm.get('phone').patchValue(this.bookingData.phone);
        this.checkInForm.get('whatsappNo').patchValue(this.bookingData.whatsappNo);
        this.checkInForm.get('noOfAdults').patchValue(this.bookingData.noOfAdults.toString());
        this.checkInForm.get('noOfKids').patchValue(this.bookingData.noOfKids.toString());
        // this.checkInForm.get('kitchenSetExpenseAmount').patchValue(this.bookingData.roomId?.kitchenSetAmount);
      }, 500);
      console.log(this.bookingData);
      this.guestPhoto = this.bookingData.guestPhoto ? (this.bookingData.baseUrl + '/' + this.bookingData.guestPhoto) : null;
      this.idProof = this.bookingData.idProof ? (this.bookingData.baseUrl + '/' + this.bookingData.idProof) : null;

    }

    this.addGuest();
  }


  addGuest() {
    const add = this.checkInForm.get('guestDetails') as FormArray;
    for (let index = 0; index < (this.bookingData.noOfAdults - 1); index++) {
      const name = this.bookingData?.guestDetails[index]?.name ? this.bookingData?.guestDetails[index]?.name : '';
      const mobile = this.bookingData?.guestDetails[index]?.mobile ? this.bookingData?.guestDetails[index]?.mobile : '';
      add.push(this.fb.group({
        name: [name],
        mobile: [mobile],
      }));
    }

  }

  openCamera(type) {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: 1,
      correctOrientation: true,
      allowEdit: false
    };
    this.camera.getPicture(options).then((imageData) => {
      if (type === 'idProof') {
        this.idProof = this.win.Ionic.WebView.convertFileSrc(imageData);
        this.idProofLoader = true;
      }else if (type === 'idProofTwo') {
        this.idProofTwo = this.win.Ionic.WebView.convertFileSrc(imageData);
        this.idProofTwoLoader = true;
      } else {
        this.guestPhoto = this.win.Ionic.WebView.convertFileSrc(imageData);
        this.guestPhotoLoader = true;
      }
      this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
        entry.file(file => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const imgBlob = new Blob([reader.result], {
              type: file.type
            });
            const formData = new FormData();
            formData.append(type, imgBlob, file.name);
            formData.append('actionType', 'CHECKIN_CHECKLIST_PHOTO_UPDATE');
            this.bookingService.updateBooking(formData, this.bookingData._id).subscribe(resp => {
              console.log(resp);
              if (resp.status === 'Success') {
                this.event.publishSomeData('booking');
                this.isSpinner = false;
              }
              this.idProofLoader = false;
              this.idProofTwoLoader = false;
              this.guestPhotoLoader = false;
            }, error => {
              this.idProofLoader = false;
              this.idProofTwoLoader = false;
              this.guestPhotoLoader = false;
            });
          };
          reader.readAsArrayBuffer(file);
        });
      });
    }, (err) => {
      console.log(err);
    });
  }

  onChangeAdultsCount($event) {
    if (!($event?.target?.value > 0)) {
      return;
    }
    const diff = $event?.target?.value - this.epf.guestDetails?.length - 1;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.epf.guestDetails?.push(this.fb.group({
          name: [''],
          mobile: [''],
        }));
      }
    } else if (diff < 0) {
      console.log(this.epf.guestDetails);
      for (let i = 0; i < (diff * -1); i++) {
        this.epf.guestDetails?.removeAt(-1);
      }
    }
  }

  checkIn(type = 'checkin') {
    if (this.idProofLoader || this.guestPhotoLoader) {
      this.common.presentToast('Please wait to upload image and try again')
      return;
    }
    // if (type !== 'draft' && (!this.idProof || !this.guestPhoto)) {
    //   this.common.presentToast('Please upload id proof and guest photo to continue');
    //   return;
    // }
    this.submitted = true;
    if (this.checkInForm.valid || type === 'draft') {
      this.isSpinner = true;
      const data: any = {
        name: this.epf.name.value,
        checkIn: this.epf.checkIn.value,
        address: this.epf.address.value,
        phone: this.epf.phone.value,
        whatsappNo: this.epf.whatsappNo.value,
        noOfAdults: this.epf.noOfAdults.value,
        noOfKids: this.epf.noOfKids.value,
        guestDetails: JSON.stringify(this.checkInForm.get('guestDetails').value),
        // bookingStatus: 'CHECKED_IN',
        useKitchenSet: this.epf.useKitchenSet.value,
        kitchenSetExpenseAmount: this.epf.kitchenSetExpenseAmount.value,
        actionType:'CHECKIN_DATA_UPDATE'
      };
      if (type !== 'draft') {
        data.bookingStatus = 'CHECKED_IN';
        data.actionType='CHECKED_IN';
      }

      this.bookingService.updateBooking(data, this.bookingData._id).subscribe(resp => {
        if (resp.status === 'Success') {
          this.event.publishSomeData('booking');
          this.isSpinner = false;
          this.modalCtrl.dismiss();
        }
      }, error => {
        this.isSpinner = false;
      });
    } else {
      this.submitted = true;
    }
  }

  saveChecklist() {
    this.isSpinner=true
    const data: any = {
      checkInCheckList: this.checklists,
      actionType:'CHECKIN_CHECKLIST'
    };
    this.bookingService.updateBooking(data, this.bookingData._id).subscribe(resp => {
      if (resp.status === 'Success') {
        this.isSpinner = false;
        this.modalCtrl.dismiss();
        this.common.presentToast('Successfully Updated');
      }else{
        this.common.presentToast('Something Went Wrong');
      }
    }, error => {
      this.common.processError(error);
      this.isSpinner = false;
    });
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }
}
