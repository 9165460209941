import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiKey: any;
  profile: any = [];
  PROFILE = 'pinnaclePorfile';
  APIKEY = 'pinnacleApiKey';
  HAS_LOGGED_IN = 'pinnacleHasLoggedIn';

  hasLoggedIn = false;

  constructor() { }

  updateLoginData(res) {

    this.profile = res.data?.user;
    this.apiKey = res.data.userToken;
    this.hasLoggedIn = true;
    console.log(res, this.profile, this.apiKey, this);
    localStorage.setItem(this.PROFILE, JSON.stringify(this.profile));
    localStorage.setItem(this.APIKEY, this.apiKey);
    localStorage.setItem(this.HAS_LOGGED_IN, JSON.stringify(true));
  }

  updateProfile(data) {
    this.profile = data;
    localStorage.setItem(this.PROFILE, JSON.stringify(this.profile));
  }

  hasPermission(permission, condType = 'or') {
    if (this.profile?.permissions?.indexOf('ALL_PERMISSION') >= 0) {
      return true;
    }
    if (typeof permission === 'string') {
      if (this.profile?.permissions?.indexOf(permission) >= 0) {
        return true;
      }
    } else if (Array.isArray(permission)) {
      let anyOne = false;
      let all = true;
      permission.forEach(perm => {
        if (this.profile?.permissions?.indexOf(perm) >= 0) {
          anyOne = true;
        } else {
          all = false;
        }
      });
      if (condType === 'or') {
        return anyOne;
      } else {
        return all;
      }

    }
  }

  loadUser() {
    if (localStorage.getItem(this.APIKEY)) {
      this.apiKey = localStorage.getItem(this.APIKEY);
      this.hasLoggedIn = true;
    } else {
      this.logout();
    }
    if (JSON.parse(localStorage.getItem(this.PROFILE))) {
      this.profile = JSON.parse(localStorage.getItem(this.PROFILE));
    } else {
    }


  }


  logout() {
    localStorage.removeItem(this.PROFILE);
    localStorage.removeItem(this.APIKEY);
    localStorage.removeItem(this.HAS_LOGGED_IN);
    localStorage.clear();
    this.hasLoggedIn = false;
  }

}
