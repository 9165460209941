import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../providers/auth.service';
import { CommonService } from '../providers/common';
import { UserService } from '../providers/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm: any = FormGroup;
  submitted = false;
  isLoading = false;

  constructor(public fb: FormBuilder,
    private auth: AuthService,
    public user: UserService,
    public router: Router,
    public modalCtrl: ModalController,
    private common: CommonService) { }

  get apf() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmitMobile() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.isLoading = true;
      const data = {
        username: this.apf.username.value,
        userType: 'Admin',
        password: this.apf.password.value
      };
      this.auth.login(data).subscribe({
        next: (resp: any) => {
          if (resp.status === 'Success') {
            console.log(resp);
            this.user.updateLoginData(resp);
            this.modalCtrl.dismiss();
            this.router.navigate(['/invoice-list']);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.common.processError(error);
          this.isLoading = false;
        },
      });
    }
  }
}
