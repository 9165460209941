import { PayoutRequestListPageModule } from './vendor/payout-request-list/payout-request-list.module';
import { PayoutRequestListPage } from './vendor/payout-request-list/payout-request-list.page';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginActivate } from './providers/loginActivate';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    // canActivate: [LoginActivate]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'booking-details/:id',
    loadChildren: () => import('./booking/booking-details/booking-details.module').then(m => m.BookingDetailsPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'add-products',
    loadChildren: () => import('./product/add-products/add-products.module').then(m => m.AddProductsPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'purchase-request',
    loadChildren: () => import('./stock/purchase-request/purchase-request.module').then(m => m.PurchaseRequestPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'stock-list',
    loadChildren: () => import('./stock/stock-list/stock-list.module').then(m => m.StockListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'products-view/:id',
    loadChildren: () => import('./product/products-view/products-view.module').then(m => m.ProductsViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'booking-success',
    loadChildren: () => import('./booking/booking-success/booking-success.module').then(m => m.BookingSuccessPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'rooms-list',
    loadChildren: () => import('./room/rooms-list/rooms-list.module').then(m => m.RoomsListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'rooms-view/:id',
    loadChildren: () => import('./room/rooms-view/rooms-view.module').then(m => m.RoomsViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'booking-request-list',
    loadChildren: () => import('./booking/booking-request-list/booking-request-list.module').then(m => m.BookingRequestListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'feedback-questions-list',
    loadChildren: () => import('./feedback/feedback-questions-list/feedback-questions-list.module').then(m => m.FeedbackQuestionsListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'booking-request-view/:id',
    loadChildren: () => import('./booking/booking-request-view/booking-request-view.module').then(m => m.BookingRequestViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'finance-category',
    loadChildren: () => import('./finance/finance-category/finance-category.module').then(m => m.FinanceCategoryPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'finance-category-add',
    loadChildren: () => import('./finance/finance-category-add/finance-category-add.module').then(m => m.FinanceCategoryAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'transaction-list',
    loadChildren: () => import('./finance/transaction-list/transaction-list.module').then(m => m.TransactionListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'invoice-list',
    loadChildren: () => import('./invoice/invoice-list/invoice-list.module').then(m => m.InvoiceListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'invoice-details/:id',
    loadChildren: () => import('./invoice/invoice-details/invoice-view.module').then(m => m.InvoiceViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'transaction-add',
    loadChildren: () => import('./finance/transaction-add/transaction-add.module').then(m => m.TransactionAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'product-categories',
    loadChildren: () => import('./product/product-categories/product-categories.module').then(m => m.ProductCategoriesPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'product-category-add',
    loadChildren: () => import('./product/product-category-add/product-category-add.module').then(m => m.ProductCategoryAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'product-list',
    loadChildren: () => import('./product/product-list/product-list.module').then(m => m.ProductListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'finance-vendors-list',
    loadChildren: () => import('./vendor/finance-vendors-list/finance-vendors-list.module').then(m => m.FinanceVendorsListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'finance-vendors-add',
    loadChildren: () => import('./vendor/finance-vendors-add/finance-vendors-add.module').then(m => m.FinanceVendorsAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'booking-food-list',
    loadChildren: () => import('./booking/booking-food-list/booking-food-list.module').then(m => m.BookingFoodListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'purchase-request-add',
    loadChildren: () => import('./stock/purchase-request-add/purchase-request-add.module').then(m => m.PurchaseRequestAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'booking-list',
    loadChildren: () => import('./booking/booking-list/booking-list.module').then(m => m.BookingListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'discount-list',
    loadChildren: () => import('./booking/discount-list/discount-list.module').then(m => m.DiscountListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'discount-details/:id',
    loadChildren: () => import('./booking/discount-details/discount-details.module').then(m => m.DiscountDetailsPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'refund-details/:id',
    loadChildren: () => import('./booking/refund-details/refund-details.module').then(m => m.RefundDetailsPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'refund-list',
    loadChildren: () => import('./booking/refund-list/refund-list.module').then(m => m.RefundListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'bank-detail-list',
    loadChildren: () => import('./finance/bank-detail-list/bank-detail-list.module').then(m => m.BankDetailListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'bank-detail-add',
    loadChildren: () => import('./finance/bank-detail-add/bank-detail-add.module').then(m => m.BankDetailAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'purchase-request-view/:id',
    loadChildren: () => import('./stock/purchase-request-view/purchase-request-view.module').then(m => m.PurchaseRequestViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'feedback-list',
    loadChildren: () => import('./feedback/feedback-list/feedback-list.module').then(m => m.FeedbackListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'feedback-view',
    loadChildren: () => import('./feedback/feedback-view/feedback-view.module').then(m => m.FeedbackViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'users-list',
    loadChildren: () => import('./user/users-list/users-list.module').then(m => m.UsersListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'users-add',
    loadChildren: () => import('./user/users-add/users-add.module').then(m => m.UsersAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'purchase-sales-list/:type',
    loadChildren: () => import('./stock/purchase-sales-list/purchase-sales-list.module').then(m => m.PurchaseSalesListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'purchase-sales-add',
    loadChildren: () => import('./stock/purchase-sales-add/purchase-sales-add.module').then(m => m.PurchaseSalesAddPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'purchase-sales-view/:id',
    loadChildren: () => import('./stock/purchase-sales-view/purchase-sales-view.module').then(m => m.PurchaseSalesViewPageModule)
  },
  {
    path: 'breakfast-list',
    loadChildren: () => import('./booking/breakfast-list/breakfast-list.module').then(m => m.BreakfastListPageModule)
  },
  {
    path: 'transfer-request-list',
    loadChildren: () => import('./finance/transfer-request-list/transfer-request-list.module').then(m => m.TransferRequestListPageModule)
  },
  {
    path: 'transfer-request-add',
    loadChildren: () => import('./finance/transfer-request-add/transfer-request-add.module').then(m => m.TransferRequestAddPageModule)
  },
  {
    path: 'vendor-view/:id',
    loadChildren: () => import('./vendor/vendor-view/vendor-view.module').then(m => m.VendorViewPageModule)
  },
  {
    path: 'payout-request-list',
    loadChildren: () => import('./vendor/payout-request-list/payout-request-list.module').then(m => m.PayoutRequestListPageModule)
  },
  {
    path: 'payout-request-add',
    loadChildren: () => import('./vendor/payout-request-add/payout-request-add.module').then(m => m.PayoutRequestAddPageModule)
  },
  {
    path: 'download-invoice',
    loadChildren: () => import('./booking/download-invoice/download-invoice.module').then(m => m.DownloadInvoicePageModule)
  },
  {
    path: 'activity-log',
    loadChildren: () => import('./activity-log/activity-log/activity-log.module').then(m => m.ActivityLogPageModule)
  },
  {
    path: 'activity-log-view/:id',
    loadChildren: () => import('./activity-log/activity-log-view/activity-log-view.module').then(m => m.ActivityLogViewPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat-list/chat-list.module').then(m => m.ChatListPageModule)
  },
  {
    path: 'chat-view',
    loadChildren: () => import('./chat/chat-view/chat-view.module').then(m => m.ChatViewPageModule)
  },
  {
    path: 'tax-list',
    loadChildren: () => import('./tax/tax-list/tax-list.module').then(m => m.TaxListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'tax-view/:id',
    loadChildren: () => import('./tax/tax-view/tax-view.module').then(m => m.TaxViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'expense-list',
    loadChildren: () => import('./expense/expense-list/expense-list.module').then(m => m.ExpenseListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'expense-list',
    loadChildren: () => import('./expense/expense-list/expense-list.module').then(m => m.ExpenseListPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'expense-add',
    loadChildren: () => import('./expense/expense-add/expense-add.module').then(m => m.ExpenseAddPageModule)
  },
  {
    path: 'expense-view/:id',
    loadChildren: () => import('./expense/expense-view/expense-view.module').then(m => m.ExpenseViewPageModule),
    canActivate: [LoginActivate]
  },
  {
    path: 'tax-view/:id',
    loadChildren: () => import('./tax/tax-view/tax-view.module').then(m => m.TaxViewPageModule),
    canActivate: [LoginActivate]
  },
  

  // {
  //   path: 'tax-add',
  //   loadChildren: () => import('./tax/tax-add/tax-add.module').then( m => m.TaxAddPageModule)
  // },
  // {
  //   path: 'tax-list',
  //   loadChildren: () => import('./tax/tax-list/tax-list.module').then( m => m.TaxListPageModule)
  // },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
